<template>
  <div>
    <v-row class="foundation" v-if="project.companyInfo !== null">
      <v-col offset-md="3" md="6" sm="12">
        <div class="text-h5 text-md-h4 text-center mb-8 form-title tway-gray-dark--text">Presentación de Proyecto</div>
        <div
          class="text-h5 text-md-h4 text-left mb-2 form-title tway-gray-dark--text"
          style="font-size: 17pt !important"
        >
          Perfil de cliente
          <v-tooltip bottom max-width="20em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
            </template>
            <span>Sólo podrás ver estos datos por el momento</span>
          </v-tooltip>
        </div>
        <v-card class="py-6 mb-10" elevation="4">
          <div class="px-6">
            <div class="input-label">Negocio principal</div>
            <div class="text-profile roboto" v-if="project.companyInfo.mainIndustrialSector !== null">
              {{ project.companyInfo.mainIndustrialSector !== null ? project.companyInfo.mainIndustrialSector : "" }}
            </div>
          </div>
          <v-divider class="mb-4 mt-3" />
          <div class="px-6">
            <div class="input-label">Acerca de la Empresa</div>
            <div class="text-profile roboto">
              {{ project.companyInfo.contributionValue !== null ? project.companyInfo.contributionValue : "" }}
            </div>
          </div>
        </v-card>

        <div
          class="text-h5 text-md-h4 text-left mb-2 form-title tway-gray-dark--text"
          style="font-size: 17pt !important"
        >
          Desafio estrategico
          <v-tooltip bottom max-width="20em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
            </template>
            <span>El desafío estratégico es tu prioridad para mejorar tu IMTD.</span>
          </v-tooltip>
        </div>
        <v-card class="py-6 mb-6" elevation="4">
          <div class="px-6">
            <div>
              <div class="d-flex justify-space-around">
                <div class="text-center">
                  <div class="input-label mb-4" style="font-weight: 600">
                    IMTD
                    <v-tooltip bottom max-width="20em">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mb-2" color="deep-purple" dark v-bind="attrs" v-on="on" size="1em">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span
                        >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio urna eros, orci accumsan semper
                        id. Bibendum orci, sollicitudin nullam vitae egestas molestie quam.</span
                      >
                    </v-tooltip>
                  </div>
                  <v-progress-circular
                    :indeterminate="!imtd"
                    :rotate="360"
                    :size="100"
                    :width="14"
                    :value="55"
                    color="#16C62E"
                  >
                    {{ imtd }}%
                  </v-progress-circular>
                </div>
                <div class="d-block mr-4">
                  <div class="d-flex mb-2 justify-space-around">
                    <div>
                      <div class="text-subtitle-1 mb-2">Dimensión a impactar</div>
                      <div class="tway-headline-3 mr-4">
                        {{ project && project.dimension ? project.dimension.name : "" }}
                      </div>
                    </div>
                    <v-progress-circular
                      class="ml-6"
                      :indeterminate="!dimensionDti"
                      :rotate="360"
                      :size="60"
                      :width="8"
                      :value="20"
                      color="#16C62E"
                    >
                      {{ dimensionDti }}%
                    </v-progress-circular>
                  </div>
                  <v-divider class="mb-4 mt-4" />
                  <div class="d-flex mb-2 justify-space-around">
                    <div>
                      <div class="text-subtitle-1 mb-2">Componente a impactar</div>
                      <div class="tway-headline-3 mr-4">
                        {{ project && project.subDimension ? project.subDimension.name : "" }}
                      </div>
                    </div>
                    <v-progress-circular
                      :indeterminate="!subdimensionDti"
                      class="ml-6"
                      :rotate="360"
                      :size="60"
                      :width="8"
                      :value="30"
                      color="#16C62E"
                    >
                      {{ subdimensionDti }}%
                    </v-progress-circular>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <div class="d-flex justify-center align-center">
          <v-btn
            class="text-uppercase text-body-1 roboto lower mr-6"
            rounded
            text
            x-small
            color="deep-purple accent-4"
            @click="$router.back()"
          >
            Volver
          </v-btn>
          <v-btn large depressed rounded class="tway-violet white--text roboto elevation-3 ml-6" @click="goToProject">
            ver descripción de proyecto
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CallForTenderService from "@/services/callForTenderService";

export default {
  name: "ProjectPresentation",
  data() {
    return {
      valid: false,
      isLoading: false,
      dimensions: null,
      showIMTD: false,
      dtiData: {
        surveyId: null,
        dtiValue: null,
        dimensionValue: null,
        subDimensionValue: null,
        dimensionId: "",
        subDimensionId: "",
      },
      formData: {
        dimension: null,
        subDimension: null,
      },
      project: null,
    };
  },
  async created() {
    await this.getProject(this.$route.params.projectId);
  },
  methods: {
    async getProject(id) {
      CallForTenderService.getProject(id)
        .then((p) => {
          if (!p) {
            this.$router.push("my-projects");
          }
          this.project = p;
        })
        .catch((err) => this.$log.error(err));
    },
    goToProject() {
      this.$router.push(`/project-description/${this.project.id}`);
    },
    toFixed(num, fixed) {
      var re = new RegExp("^-?\\d+(?:\\.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    },
  },
  computed: {
    imtd() {
      if (this.project && this.project.initialScore) {
        return this.toFixed(this.project.initialScore * 10, 0);
      }
      return null;
    },
    dimensionDti() {
      if (this.project && this.project.dimension) {
        let dimension = this.project.dimension;
        return this.toFixed((dimension.idx * 10) / dimension.value, 0);
      }
      return null;
    },
    subdimensionDti() {
      if (this.project && this.project.subDimension) {
        let subDimension = this.project.subDimension;
        return this.toFixed((subDimension.idx * 10) / subDimension.value, 0);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.form-title {
  font-size: 19pt !important;
}
.text-profile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}
</style>

<style>
.v-application .foundation .v-list-item__title {
  font-family: "Roboto" !important;
}
.foundation .v-progress-circular__info {
  color: #4b168c !important;
}
</style>
